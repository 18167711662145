import React, {Fragment, useEffect, useState} from 'react';
import {Link, RouteComponentProps, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import ReactGA from 'react-ga';

import * as api from '../../api';
import * as authActions from '../../store/auth/actions';
import * as dbActions from '../../store/db/actions';
import styles from './SignIn.module.scss';
import * as ROUTES from '../../constants/routes';

interface Props extends RouteComponentProps {
  path?: string;
}
interface State {
  email: string;
  password: string;
  signinSucceeded: boolean;
}

const INITIAL_STATE = {
  email: '',
  password: '',
  signinSucceeded: false
};

const SignIn = (props: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState<State>(INITIAL_STATE);
  const history = useHistory();
  const [showResendEmail, setShowResendEmail] = useState<boolean>(false);
  const [isLoggedOut, setLoggedOut] = useState<boolean>(false);

  ReactGA.pageview(ROUTES.SIGN_IN);

  useEffect(() => {
    if (!isLoggedOut) {
      setLoggedOut(true);

      api.post('auth/logout', {}).catch(() => {
        // Do nothing
      });
    }
  }, [isLoggedOut]);

  const onSubmit = (event: any) => {
    api.post('auth/login', getState())
        .then(value => {
          api.get('user').then(user => {
            setState({ ...INITIAL_STATE, signinSucceeded: true });
            dispatch(authActions.storeUser(user));
            dispatch(dbActions.storePlayer(user.player));
            history.push(ROUTES.HOME);
          })
          
        })
        .catch(error => {
          console.error(error);
          // if (error.response?.data?.message === 'EMAIL_ADDRESS_NOT_VERIFIED') {
            // setShowResendEmail(true);
          // }
          toast(api.transformErrorResponse(error, t), {type: toast.TYPE.ERROR});
        });

    event.preventDefault();
  };

  const getState = () => {
    const { email, password } = state;

    return {
      'name': email,
      'password': password
    };
  };

  const onChange = (event: any) => {
    if (showResendEmail) {
      setShowResendEmail(false);
    }
    setState({ ...state, [event.target.name]: event.target.value } as any);
  };

  const onRegister = () => {
    return history.push(ROUTES.SIGN_UP);
  };

  const resendEmail = (event: any) => {
    event.preventDefault();
    api.post('auth/verify-email/resend', getState())
        .then(() => {
          setShowResendEmail(false);
          toast(t('RESEND_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
        })
        .catch(error => {
          toast(api.transformErrorResponse(error, t), {type: toast.TYPE.ERROR});
        });
  };

  const { email, password } = state;

  if (state.signinSucceeded) {
    const path = props.path;
    history.push(path ? path : '/');
  }

  const login: string = t('LOGIN') as string;

  return (
      <Fragment>
        <div className={styles.container}>
          <form onSubmit={onSubmit}>
            <div className={styles.row}>
              <h2 style={{textAlign: 'center'}}>{t('LOGIN_HEADER') as string}</h2>
              {/*<div className={styles.vl}>
                <span className={styles.inner}>{t('OR')}</span>
              </div>*/}

              {/* <div className={styles.col}>
                <button className={cn(styles.fb, 'btn')}>
                  <i className={cn('fa', 'fa-facebook', 'fa-fw')} /> {t('LOGIN_FACEBOOK')}
                </button>
                <button className={cn(styles.twitter, 'btn')}>
                  <i className={cn('fa', 'fa-twitter', 'fa-fw')} /> {t('LOGIN_TWITTER')}
                </button>
                <button className={cn(styles.google, 'btn')}>
                  <i className={cn('fa', 'fa-google', 'fa-fw')} /> {t('LOGIN_GOOGLE')}
                </button>
              </div> */}

              <div className={styles.form}>
                {/*<div className={styles.info} dangerouslySetInnerHTML={{__html: t('LOGIN_TEXT')}} />*/}
                {/*<div className={styles.hideMdLg}>
                  <p>Or sign in manually:</p>
                </div>*/}

                <input type="text" name="email" placeholder={t('USERNAME') as string} required value={email} onChange={onChange} />
                <input type="password" name="password" placeholder={t('PASSWORD') as string} required value={password} onChange={onChange} />
                <input type="submit" value={login} />
              </div>

            </div>
          </form>
          {/*<div className={styles.form}>
            <p>{t('SIGN_UP_INFO') as string}</p>
            <input type="button" value={t('SIGN_UP') as string} onClick={onRegister} />
          </div>*/}
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Link to={ROUTES.PASSWORD_FORGET} >{t('FORGOT_PASSWORD') as string}</Link>
            </div>
            <div className={styles.col}>
              {showResendEmail && <a href="resend" onClick={resendEmail}>{t('VERIFY_EMAIL_RESEND') as string}</a>}
            </div>
          </div>
        </div>
      </Fragment>
  );
}

export default SignIn;
