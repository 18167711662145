import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cn from 'classnames';

import * as ROUTES from '../constants/routes';
import { Competition, News } from '../store/db/types';
import * as actions from '../store/db/actions';

import styles from './Home.module.scss';

import SelectCompetition from '../components/Competition/SelectCompetition';
import AdSense from '../components/AdSense';

const Home = () => {

  const {t} = useTranslation();
  const competition: Competition | null = useSelector((state: any) => state.db.competition); // eslint-disable-line
  const commonNews: News[] = useSelector((state: any) => state.db.commonNews); // eslint-disable-line
  const [showSelectPopup, setShowSelectPopup] = useState<boolean>(false);

  useEffect(() => {
    if (commonNews.length === 0) {
      actions.getCommonNews();
    }
  }, [commonNews]);

  const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
  const isRegistrationOpen = (): boolean => {
    const registrationFromMoment = moment(competition?.registration_from ? competition.registration_from : 0);
    const registrationUntilMoment = moment(competition?.registration_to ? competition.registration_to : 0);
    const now = moment();

    return registrationFromMoment.isBefore(now) && registrationUntilMoment.isAfter(now);
  }

  const isCompetitionActive = (): boolean => {
    const registrationUntilMoment = moment(competition?.registration_to ? competition.registration_to : 0);
    const now = moment();

    return registrationUntilMoment.isBefore(now);
  }

  const renderRegistered = (registered: string | null) => {
    if (registered === 'true') {
      return (<div className="info">
        Je account is geregistreerd.<br />
        Er is een e-mail gestuurd naar het opgegeven e-mailadres om dit adres te verifieren.
      </div>)
    }
    return null;
  };
  
  const renderSuccess = () => {
    return (
        <div className="success">
          <p>Het is weer mogelijk om de uitslagen in te voeren.</p>
        </div>);
  }
  
  const renderError = () => {
    return (
        <div className={styles.error}>
          <p>Er is op dit moment helaas een probleem met het invoeren van de uitslagen.<br/>
          We zijn druk bezig dit op te lossen.</p>
        </div>);
  }

  const renderWarning = () => {
    return (
        <div className={styles.warning}>
          <p>Door omstandigheden zijn alle wachtwoorden gereset.<br/>
          Heb je al een account, en is je wachtwoord nog niet hersteld, ga dan naar <i>Aanmelden</i> en vervolgens <i>Wachtwoord vergeten</i> om je wachtwoord te herstellen.</p>
        </div>);
  }

  const renderText = () => {
    if (competition?.sport === 'Tennis') {
      return (
          <div className="info">
            <p><b>Mededeling van de competitie beheerder:</b></p>
            <p>Alle spelers van deze competitie zijn al bekend. Zie bovenstaand om je gebruikersnaam op te vragen of je wachtwoord te herstellen, indien nodig.</p>
          </div>);
    }
    return null;
  }

  const renderNews = () => {
    return commonNews
        .filter(item => moment(item.from).unix() < Date.now())
        .map((item, idx) => {
          return <div className="info" key={idx}><i className="fa fa-info-circle" />{item.content}</div>
        });
  };

  const renderEmail = () => {
    if (!competition) {
      return t('NOT_SELECTED');
    }

    return <a href={'mailto:' + competition.email} target="_blank" rel="noopener noreferrer">{competition.email}</a>;
  };

  const renderRegistration = () => {
    const registrationFromMoment = moment(competition?.registration_from ? competition.registration_from : 0);
    const registrationUntilMoment = moment(competition?.registration_to ? competition.registration_to : 0);
    const now = moment();
    if (registrationFromMoment.isAfter(now)) {
      return (<React.Fragment>
        <strong>{t('REGISTRATION_OPEN_FROM') as string}</strong>{registrationFromMoment.format(t('DATE_FORMAT'))}<br/>
      </React.Fragment>);
    }
    else if (registrationUntilMoment.isAfter(now)) {
      return (<React.Fragment>
        <strong>{t('REGISTRATION_OPEN_UNTIL') as string}</strong>
        {registrationUntilMoment.format(t('DATE_FORMAT') as string)}
        <br/>
      </React.Fragment>);
    }
    return null;
  }

  const renderRegister = () => {
    if (!isRegistrationOpen()) {
      return null;
    }
    return <Route render={({history}) => (
      <input
          type="button"
          value={t('REGISTER') as string}
          className={cn(styles.lastButton, 'bright')}
          onClick={() => history.push(ROUTES.REGISTER, { competition})}
      />
    )} />;
  }

  const renderEnterResult = () => {
    if (!isCompetitionActive()) {
      return null;
    }
    return (<div><Route render={({history}) => (
      <input
          type="button"
          value={t('ENTER_RESULT') as string}
          className={cn(styles.lastButton, 'bright')}
          onClick={() => history.push(ROUTES.CONTESTANT_MATCHES)}
      />
    )} /></div>);
  }

  const renderSignup = () => {
    if (competition?.sport === 'Tennis') {
      return
    }

    return (<div className={styles.signup}>
      <p dangerouslySetInnerHTML={{__html: t('SIGN_UP_EXPL')}} />
      <Route render={({history}) => (
          <input
              type="button"
              value={'' + t('SIGN_UP') as string}
              className="signup"
              onClick={() => history.push(ROUTES.SIGN_UP)}
          />
      )} />
    </div>);
  };

  return (
      <div className={styles.Home}>
        {renderRegistered(useQuery().get('registered'))}
        {renderNews()}
        {/*renderSuccess()*/}
        {/*renderError()*/}
        {/* {renderWarning()} */}
        {/* {renderText()} */}
        <div className={styles.competition}>
          <h2 dangerouslySetInnerHTML={{__html: t('HOME_TEXT')}} />
          <p dangerouslySetInnerHTML={{__html: t('HOME_COMPETITION_HEADER')}} />
          <strong>{t('CLUB') as string}</strong>{competition ? competition.club_name : t('NOT_SELECTED') as string}<br/>
          <strong>{t('SPORT') as string}</strong>{competition ? t(competition.sport.toUpperCase()) as string : t('NOT_SELECTED') as string}<br/>
          <strong>{t('COMPETITION') as string}</strong>{competition ? competition.name : t('NOT_SELECTED') as string}<br/>
          <strong>{t('MANAGER') as string}</strong>{competition ? competition.manager : t('NOT_SELECTED') as string}<br/>
          <strong>{t('EMAIL_ADDRESS') as string}</strong>{renderEmail()}<br/>
          {renderRegistration()}<br />
          <input
              type="button"
              value={t('SELECT_COMPETITION') as string}
              className={styles.button}
              onClick={() => setShowSelectPopup(true)}
          />
          {renderRegister()}
          {renderEnterResult()}
        </div>
        {renderSignup()}
        <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
        {showSelectPopup
            ? <SelectCompetition
                onClose={() => setShowSelectPopup(false)}
            />
                : null}
      </div>
  );
};

export default Home;