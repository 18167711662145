import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import * as types from '../../store/db/types';
import * as utils from '../../utils';

interface Props {
  match: types.Match;
  type: 'Manager' | 'Player' | 'Unknown';
  onSave(result: types.Match): void;
  onClose(): void;
}

const EditResult = ({match, type, onSave, onClose}: Props) => {

  const {t} = useTranslation();

  const [result, setResult] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>();

  useEffect(() => {
    setResult(match.result || '');
  }, [match.result]);

  const onSaveResult = () => {

    // Set result and reserves played to match
    if (result !== undefined) {
      match.result = result;
    }


    if (result !== undefined && type === 'Manager') {
      onSave(match);
    } else {
      if (!result) {
        setErrMsg(t('ERROR_REQUIRED'));
      } else if (!isResultCorrect(result)) {
        setErrMsg(t('ERROR_NOT_VALID'));
      } else {
        onSave(match);
      }
    }
  };

  const isResultCorrect = (currentResult: string) => {
    if (match.count_type) {
      if (+match.count_type === types.CountType.BEST_OF_THREE.valueOf()) {
        return currentResult.match('[01234567]-[01234567] [01234567]-[01234567]')
            || currentResult.match('[01234567]-[01234567] [01234567]-[01234567] [01234567]-[01234567]');
      } else if (+match.count_type === types.CountType.TWO_FULL_SETS.valueOf()) {
        return currentResult.match('[01234567]-[01234567] [01234567]-[01234567]');
      } else if (+match.count_type === types.CountType.TWO_SETS_EQUALS_ON_6.valueOf()) {
        return currentResult.match('[01234567]-[01234567] [01234567]-[01234567]');
      } else if (+match.count_type === types.CountType.COUNT_GAMES.valueOf()) {
        return currentResult.match('\\d-\\d');
      }
    }
    return false;
  }

  return (
      <div className="popup">
        <div className="header">{t('EDIT_RESULT_TITLE') as string}</div>
        <div className="content">
          <p>{t('EDIT_RESULT_TEXT') as string}</p>
          <p>{utils.getRegistrationName(t, match, 'H') + ' - ' + utils.getRegistrationName(t, match, 'A')}</p>
          <label style={{color: 'red'}}>{errMsg}</label>
          <input
              type="text"
              value={result}
              pattern="[0123456]-[0123456] [0123456]-[0123456]"
              placeholder={t('RESULT')}
              required={type !== 'Manager'}
              onChange={(event) => setResult(event.target.value)}
          />
          <input
              type="button"
              value={'' + t('SAVE') as string}
              className="button"
              onClick={onSaveResult}
          />
          <input
              type="button" value={'' + t('CANCEL') as string}
              className="lastButton second"
              onClick={onClose}
          />
        </div>
      </div>
  );
}

export default EditResult;