import React, { Fragment, useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useForm} from "react-hook-form";

import ReCAPTCHA from 'react-google-recaptcha';

import * as api from '../../api';

import Breadcrumb from 'components/Breadcrumb';
import ErrorMessageRequired from 'components/Validation/ErrorMessageRequired';

import styles from 'containers/Auth/SignUp.module.scss';

interface Form {
  accountName: string;
  email: string;
  captchaToken: string | null;
}

const PasswordForget = () => {
  const {t} = useTranslation();
  const { register, handleSubmit, getValues, setValue, errors } = useForm<Form>({mode: "onBlur"});
  const [isGetPassword, setGetPassword] = useState<boolean>(true);

  useEffect(() => {
    register({name: 'captchaToken'}, {required: true});    
  });

  const onSubmit = (data: Form) => {
    if (isGetPassword) {
      api.post(`auth/forgot-password`, { name: data.accountName})
        .then(() => {
          toast(t('SEND_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
          setValue('accountName', '');
          setValue('captchaToken', null);
        })
        .catch(error => {
          toast(api.transformErrorResponse(error, t), {type: toast.TYPE.ERROR});
        });
    } else {
      api.post(`auth/forgot-username`, { email: data.email})
        .then(() => {
          toast(t('SEND_SUCCESS') as string, {type: toast.TYPE.SUCCESS});
          setValue('email', '');
          setValue('captchaToken', null);
        })
        .catch(error => {
          toast(t(error.response.data) as string, {type: toast.TYPE.ERROR});
        });
    }
  };

  const onVerifyCaptcha = (token: string | null) => {
    setValue('captchaToken', token);
  };

  const getPasswordForget = () => {
    return (
      <>
        <p dangerouslySetInnerHTML={{__html: t('PASSWORD_FORGET_TEXT') as string}} />
        <input name="accountName" ref={register({required: true})} type="text" placeholder={t('ACCOUNT_NAME') as string} />
        <ErrorMessageRequired errors={errors.accountName} />
      </>
    );
  };

  const getEmailForget = () => {
    return (
      <>
        <p dangerouslySetInnerHTML={{__html: t('USERNAME_FORGET_TEXT') as string}} />
        <input name="email" ref={register({required: true})} type="text" placeholder={t('EMAIL_ADDRESS') as string} />
        <ErrorMessageRequired errors={errors.email} />
        </>
    );
  }

  return (
    <Fragment>
      <Breadcrumb title="PASSWORD_FORGET" />
      <div className={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <div className={styles.form}>
              <input type="radio" name="passwordForget" id="id1" checked={isGetPassword} onChange={() => setGetPassword(true)} /><label htmlFor="id1">Ik weet mijn gebruikersnaam en wil mijn wachtwoord herstellen</label><br /><br />
              <input type="radio" name="passwordForget" id="id2" checked={!isGetPassword} onChange={() => setGetPassword(false)} /><label htmlFor="id2">Ik weet mijn gebruikersnaam niet (meer)</label><br /><br />
              <hr />
              {isGetPassword ? getPasswordForget() : getEmailForget()}
              <ReCAPTCHA sitekey="6LdstbYUAAAAABylvCWs2ReAbHoA3Jt9PsMs4z0j" onChange={onVerifyCaptcha} />
              <ErrorMessageRequired errors={errors.captchaToken} />
              <input type="submit" value={isGetPassword ? t('RESET_PASSWORD')! as string : t('REQUEST_ACCOUNT_NAME')! as string} />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default PasswordForget;
