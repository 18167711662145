import React, {Component} from 'react';
import {DbReducer} from '../../store/db/reducer';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import moment from 'moment';

import * as types from '../../store/db/types';
import Breadcrumb from '../../components/Breadcrumb';
import SelectGroup from '../../components/SelectGroup';

import styles from './MatchesGroup.module.scss';
import AdSense from '../../components/AdSense';
import {ThunkDispatch} from 'redux-thunk';
import * as actions from '../../store/db/actions';
import MatchesList from '../../components/Competition/MatchesList';
import Loading from 'components/Common/Loading';

interface DispatchProps {
  addMatches: (competitionId: number, group: string) => void;
}

interface Props extends DispatchProps, WithTranslation {
  competition: types.Competition | null;
  groups: types.Group[];
  matches: types.Match[];
}
interface State {
  group: string;
  loading: boolean;
}

class MatchesGroup extends Component<Props, State> {

  state = {
    filter: '',
    group: 'none',
    loading: false
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.group !== this.state.group && this.props.competition) {
      if (!this.props.matches.find(match => match.group_name === this.state.group)) {
        this.setState({loading: true});
        this.props.addMatches(this.props.competition.id, this.state.group);
      }
    }
    if (prevProps.matches.length !== this.props.matches.length) {
      this.setState({loading: false});
    }
  }

  render() {
    const { t, competition, groups } = this.props;
    if (!competition) {
      return t('NO_COMPETITION_SELECTED');
    }
    return (
        <div className={styles.MatchesGroup}>
          <Breadcrumb competition={competition} title={'MATCHES_GROUP_TITLE'} />
          <p dangerouslySetInnerHTML={{__html: t('MATCHES_GROUP_TEXT')}} />
          <SelectGroup groups={groups} onChange={group => this.setState({group})} />
          {this.renderGroup(competition)}
          {this.state.loading && <Loading />}
          <AdSense client="ca-pub-6405877826988355" slot="3631385627" format="auto" style={{display:"block"}} responsive="true" />
        </div>
    );
  }

  renderGroup = (competition: types.Competition) => {
    const { matches } = this.props;

    if (this.state.group === 'none') {
      return null;
    }

    const groupMatches = matches
        .filter(match => match.group_id == +this.state.group)
        .sort((a, b) => moment(a.date_time || '1970-01-01').unix() - moment(b.date_time || '1970-01-01').unix());
    if (groupMatches.length === 0) {
      return null;
    }

    return <MatchesList
        competition={competition}
        matches={groupMatches}
        type="Unknown"
        onChange={() => null}
    />;
  };
}

const mapStateToProps = (state: DbReducer) => state.db;

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => ({ // eslint-disable-line
  addMatches: (competitionId: number, group: string) => dispatch(actions.addMatches(competitionId, group))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MatchesGroup));